<template>
  <div class="max-h-full min-h-full flex flex-col w-full overflow-hidden">
    <sub-header :backButton="true" :handleClick="showSectionModal" :showBtn="true" buttonText="Add Section" :backRedirectionUrl="`/check-admin/view/${$route.params.id}`" />
    <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-y-auto scroll-bar mx-6 my-6">
    <span v-if="isLoading" class="flex h-44 min-w-full bg-white items-center justify-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
        </span>
    <div v-else class="w-full flex gap-4 max-h-full overflow-y-auto" >
      <div :class="showSidePanel ? 'w-8/12': 'w-full' " class="max-h-full flex flex-col gap-3 min-full overflow-y-auto scroll-bar pr-2">
        <div class="shadow-md rounded-lg bg-white p-3 flex gap-6 flex-col">
              <div class="flex flex-col gap-3">
                    <span class="text-gray-500 text-md font-medium"> Check Name  </span>
                    <neo-input style="border: 1px solid #e8e8e8" class="rounded-lg h-10 w-2/6 " placeholder="Field Name" bg="white" v-model="checkData.name" />
              </div>
              <div class="flex flex-col gap-3 w-12/12">
                <span class="text-gray-500 text-md font-medium"> Check Description </span>
                <textarea v-model="checkData.description"  class="rounded-lg max-w-full overflow-hidden p-3 text-gray-600" placeholder="Description" style="border: 1px solid #e8e8e8" id="" cols="20" rows="5"></textarea>
                
              </div>

              <div class="mt-2 flex justify-end max-w-full gap-4">
                    <Button
                    @click="handleSaveCheck()"
                    class="btn-primary float-right mt-4"
                    text="Save"
                />
              </div>
        </div>

        <!-- Section list -->
        <div class="flex flex-col  gap-6" v-if="sectionList.length">
          <draggable
            data-source="juju"
            :list="sectionList"
            draggable=".item"
            group="section"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            class="list-group flex flex-col gap-4"
            @change="hanldeSectionReorder"
          >
            <div
              class="
                list-group-item
                item
                shadow-sm
                rounded-lg
                bg-white
                p-3
                border
                flex
                flex-col
                gap-3
                border-gray-100
                border-solid
              "
              v-for="(section) in sectionList"
              :key="section.id"
            >
              <div class="flex justify-between items-center">
                <div class="flex items-center gap-2">
                  <font-awesome-icon
                      icon="grip-vertical"
                      class="text-gray-500"
                    />

                    <input v-model="section.name" @click="sectionNameClick(section)" @blur="updateSectionLabel(section)" :class="!section.name ? 'border-b border-dashed border-red-300': 'border-b border-dashed border-gray-300' " type="text" class="section_name min-w-100 border-0  outline-none p-1 text-md  " placeholder="ADD SECTION NAME">

                    <font-awesome-icon
                      v-if="section.name"
                      icon="edit"
                      class="text-primary section_edit_icon"
                    />

  
                  
                </div>
                <div class="flex gap-3">
                  <font-awesome-icon
                    v-if="section.expanded"
                    @click="handleCollapseSecrion(section)"
                    icon="chevron-circle-up"
                    class="text-primary cursor-pointer text-lg"
                  />
                  <font-awesome-icon
                    v-else
                    @click="handleCollapseSecrion(section)"
                    icon="chevron-circle-down"
                    class="text-primary cursor-pointer text-lg"
                  />
                  <font-awesome-icon
                    @click="handleRemoveSection(section)"
                    icon="times"
                    class="cursor-pointer text-primary text-lg"
                  />
                </div>
              </div>
              <div
                v-if="section.expanded"
                class="transition-all duration-200"
              > 
              <!-- Block Dragables -->
              <div v-for="sectionBlock in section.blocks" :key="sectionBlock.id" class="border-2 p-4 my-4 border-dotted  rounded-md flex flex-col gap-3">
                <h2 class="font-semibold">Block</h2>
                <p v-if="!section.blocks.length" class="flex items-center justify-center">Add Fields to block</p>
                <draggable
                    :id="section.id"
                    data-source="juju"
                    :list="sectionBlock.fields"
                    class="
                      list-group
                      flex flex-col gap-2 transition-all duration-200
                    "
                    draggable=".item"
                    :sort="true"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                    @change="handleFieldChange"
                    @add="handleAddFieldChange"
                  >

                  <transition-group
                    type="transition"
                    name="flip-list"
                    class="flex flex-col gap-3"
                  >
                      <div
                        class="
                          list-group-item
                          child
                          item
                          bg-gray-100 transition-all duration-200
                          p-2 flex justify-between items-center gap-3 rounded-md
                        "
                        :class="showSidePanel && activeFieldPanel.field.id === element.id ? 'border-solid  bg-blue-200 border-primary border-r-4 border-0': 'hover:bg-gray-200' "
                        v-for="element in sectionBlock.fields"
                        :key="element.id"
                        @click="handleShowPanel(element, section)"
                      >
                        <div class="flex gap-3 items-center">
                          <font-awesome-icon
                            icon="grip-vertical"
                            class="text-gray-500"
                          />
                          <input v-model="element.label" @click="fieldNameClick(element)" @blur="updateFieldLabel(section, element)" :class="!element.label ? 'border-b border-dashed border-red-300': 'border-b border-dashed border-gray-300' " type="text" class="section_name bg-transparent min-w-100 border-0  outline-none p-1 text-md  " >

                          <font-awesome-icon
                            v-if="element.label"
                            icon="edit"
                            class="text-gray-700 section_edit_icon text-sm"
                          />
                        </div>
                        <div class="flex gap-2">
                          <font-awesome-icon
                            class="
                              text-gray-400 cursor-pointer
                            "
                            @click.stop="handleDeleteField(element, section, sectionBlock )"
                            icon="trash"
                          />

                        </div>
                      </div>

                  </transition-group>
                    

                </draggable>
                <div class="flex justify-end">
                  <Button
                    @click="showfieldAddModal(section, sectionBlock)"
                    class="btn-primary float-right mt-4"
                    text="Add field"
                />

                </div>
                
                

              </div>

                <!-- End Block Dragables -->

                <!-- fields Dragables -->
                <draggable
                  :id="section.id"
                  data-source="juju"
                  :list="section.fields"
                  class="
                    list-group
                    flex flex-col gap-2 transition-all duration-200
                  "
                  draggable=".item"
                  group="feilds"
                  :sort="true"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                  @change="handleFieldChange"
                  @add="handleAddFieldChange"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                    class="flex flex-col gap-3"
                  >
                    <div
                      class="
                        list-group-item
                        child
                        item
                        bg-gray-100 transition-all duration-200
                        
                        p-2 flex justify-between items-center gap-3 rounded-md
                      "
                      :class="showSidePanel && activeFieldPanel.field.id === element.id ? 'border-solid  bg-blue-200 border-primary border-r-4 border-0': 'hover:bg-gray-200' "
                      v-for="element in section.fields"
                      :key="element.id"
                      @click="handleShowPanel(element, section)"
                    >
                      <div class="flex gap-3 items-center">
                        <font-awesome-icon
                          icon="grip-vertical"
                          class="text-gray-500"
                        />
                        <input v-model="element.label" @click="fieldNameClick(element)" @blur="updateFieldLabel(section, element)" :class="!element.label ? 'border-b border-dashed border-red-300': 'border-b border-dashed border-gray-300' " type="text" class="section_name bg-transparent min-w-100 border-0  outline-none p-1 text-md  " >

                        <font-awesome-icon
                          v-if="element.label"
                          icon="edit"
                          class="text-gray-700 section_edit_icon text-sm"
                        />
                      </div>
                      <div class="flex gap-2">
                        <font-awesome-icon
                          class="
                            text-gray-400 cursor-pointer
                          "
                          @click.stop="handleDeleteField(element, section)"
                          icon="trash"
                        />

                      </div>
                    </div>
                  </transition-group>
                </draggable>

                <Button
                    @click="showfieldAddModal(section)"
                    class="btn-primary float-right mt-4"
                    text="Add field"
                />

                <!-- End Fields Dragables -->
                <!-- <button
                    @click="showfieldAddModal()"
                    class="flex btn btn-info w-2/12 my-3"
                >
                <font-awesome-icon icon="table" class="text-xl mr-3" />
                    Add field
                </button> -->
              </div>
            </div>
          </draggable>
        </div>
        <!-- end section list -->

        <!-- When No Section Available -->

        <div v-else class="rounded-lg border-2 border-dashed border-gray-300  h-40 p-3 flex items-center justify-center">
             Add Sections
        </div>
        <!-- End No section Available  -->

      </div>

      <!-- Side Panel Section -->
      <div v-if="showSidePanel && activeFieldPanel.field" class="bg-white min-h-full max-h-full overflow-y-auto shadow-md rounded-lg p-3" :class="showSidePanel && 'w-4/12' ">
          <div class="panel-head py-2 border-0 border-b border-gray-200 border-solid flex gap-3 justify-between items-center">
              <span class="text-gray-800 text-md font-semibold text-lg">{{activeFieldPanel.field.label}}  </span>
              <font-awesome-icon
                  @click="handleClosePanel"
                  icon="times"
                  class="cursor-pointer text-primary text-xl"
              />
          </div>
          <div class="py-3">
              <!-- <h4 class="text-gray-600 m-0 p-0 text-md font-medium">Visibility</h4> -->
              <div v-if="activeFieldPanel.field && activeFieldPanel.field.roles && activeFieldPanel.field.roles.length" class="py-3">
                  <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-100 mb-2">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Read</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Write</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Visible</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Mandatory</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(role, id) in activeFieldPanel.field.roles" :key="id" class="visibility_row">
                                        <td class="px-3 py-2 whitespace-nowrap inline-flex text-gray-600">
                                            {{role.title}}
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input type="checkbox" class="checkbox checkbox-primary" v-model="role.role_read" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input type="checkbox" class="checkbox checkbox-primary" v-model="role.role_write" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input type="checkbox" class="checkbox checkbox-primary" v-model="role.role_visible" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input type="checkbox" class="checkbox checkbox-primary" v-model="role.role_mandatory" />
                                        </td>
                                    </tr>
                                </tbody>
                    </table> 
              </div>
              <p v-else class="text-center py-5 text-gray-500">No Roles Found</p>
          </div>
          <div v-if="activeFieldPanel.field && activeFieldPanel.field.roles && activeFieldPanel.field.roles.length" class="flex justify-end mt-6">
            <Button @click="updateFieldLabel(activeFieldPanel.section, activeFieldPanel.field)" text="Update Field" class="btn-primary" />

          </div>
      </div>


      <!-- End Side Panel Section -->

    </div>

    </div>

    <modal-content
      ref="section-modal"
      :max-width="480"
      name="sections"
      title="Add New Section"
      @close="closeSectionModal"
    >
      <template #content>
        <div class="flex-1 " >
          <div class="flex flex-col mb-4">
            <span class="text-gray-6 inline-block py-2"
              >Please Enter Section Name</span
            >
              <neo-input
                placeholder="Section Name"
                bg="white"
                v-model="sectionName"
              >
              </neo-input>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex w-full">
          <Button
                @click="addNewSection"
                class="btn-primary"
                :disabled="!sectionName"
                text="Create new section "
            />
          
        </div>
      </template>
    </modal-content>

<!-- delete popup confirm -->

      <modal-confirm
      title="Are you sure?"
      message="Please confirm you're about to delete the field."
      ref="confirm-popup"
    ></modal-confirm>


<!-- Field add Modal -->
    <modal-content
      ref="section-addfield-modal"
      :max-width="480"
      name="addfield"
      title="Add Field"
      @close="closefieldAddModal"
    >
      <template #content>
        <div class="flex-1 " >
          <div class="flex flex-col">
            <span class="text-gray-6 inline-block py-2"
              >Select Field</span
            >
              <vue-multiselect
              :multiple="false"
              :limit="1"
              :checkboxes="false"
              :taggable="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="add field"
              :show-labels="false"
              :maxHeight="200"
              :options="fieldLists"
              selectLabel=""
              track-by="id"
              label="label"
              v-model="fieldToAdd.field"
            >
            </vue-multiselect>

            <div class="shadow overflow-hidden rounded border border-solid border-gray-300 mt-4">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Role
                            </th>
                            <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Read
                            </th>
                            <th scope="col" class="p-2text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Write
                            </th>
                            <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Visible
                            </th>
                            <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Mandatory
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="fieldToAdd.permisions.length > 0">
                        <tr v-for="role in fieldToAdd.permisions" :key="role.id" >
                            <td class="px-2 py-1 text-sm">{{role.title}}</td>
                            <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500"> <Checkbox :disabled="!fieldToAdd.field" v-model="role.role_read" /> </td>
                            <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500"> <Checkbox :disabled="!fieldToAdd.field" v-model="role.role_write" /> </td>
                            <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500"> <Checkbox :disabled="!fieldToAdd.field" v-model="role.role_visible" /> </td>
                            <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500"> <Checkbox :disabled="!fieldToAdd.field" v-model="role.role_mandatory" /> </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                No data available
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex w-full">
          <button
            class="btn btn-block btn-info"
            @click="addFieldToSection"
            :disabled="!fieldToAdd.field"
          >
            ADD
          </button>
        </div>
      </template>
    </modal-content>


  </div>
</template>

<script>
import draggable from "vuedraggable";
import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import { rows } from './check-admin-data'
import axios from "@/axios";
import loader from "@/components/loader";
import modalContent from "@/components/modal-content";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
const modalConfirm = () => import("@/components/modal-confirm");
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
// Static use Only Remove after Proper API


// end
export default {
  name: "two-list-headerslots",
  display: "Transitions",
  order: 16,
  components: {
    draggable,
    SubHeader,
    VueMultiselect,
    "neo-input": Input,
    loader,
    modalContent,
    modalConfirm,
    Button,
    Checkbox,
  },
  data() {
    return {
      isLoading: false,
      drag: false,
      sectionList: [],
      sectionName: "",
      showSidePanel:false,
      activeFieldPanel: null,
      checkData:null,
      fieldLists: [],
      fieldToAdd: {
        section: null,
        block: null,
        field: null,
        permisions: null
      },
      tenantId: null,
      oldSectionName: "",
      oldFieldName: "",
      allRoles:[],
      fetchingRoles: false,
    };
  },
  
  async mounted() {
      if (!this.$store.getters.getTenantId) {
        await this.$store.dispatch('fetchTenantId')
      }
      this.tenantId = this.$store.getters.getTenantId
      this.fieldLists = await this.fetchFieldsList();
      await this.getCheckDetails();
      await this.fetchAllRoles()
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        
      };
    },
  },
  methods: {
    async fetchFieldsList() {
        let payload = {
            tenant_id: this.tenantId,
            req_offset: 0,
            req_limit: 1,
        };
        let url = `/fields/${this.tenantId}/all`;
        let result = await axios.get(url, payload);
        return result.data.fields;
    },

    // Fetching Roles 
    async fetchAllRoles(){
        this.fetchingRoles = true
        let url = `/tenant-check/section/field/valid-roles/`;
        try{

            let {data} = await axios.get(url);
            this.allRoles  = data.roles
        }
        catch(error){
            console.log("Error:", error)
        }
        this.fetchingRoles = false

        // result.data.fields;
    },

    async getCheckDetails() {
          this.isLoading = true
          let queryId = this.$route.params.id
          if(queryId){
            this.isLoading = true;
            let url = `/tenant-check/${this.tenantId}/${queryId}`;
            let {data} = await axios.get(url);
            try{
                this.checkData = data.data
                let sections = this.formatSections(data.data.sections)
                this.sectionList = sections
                this.isLoading =false
            }catch(error){
              this.isLoading = false
            }
            this.isLoading =false
        }
    },

    // Foramt sections

    formatSections(sections){
        let allSections = sections
        let sectionsList = []
        allSections?.map(section => {
            let sectionData = {
              blocks: [],
              id: section.id,
              name: section.name,
              order:section.order || 50,
              fields:[],
              expanded:false
            } 

            const addToBlock = (field) =>{
                let sectionBlock = sectionData.blocks.find(el => el.id === field.block_id)
                if(sectionBlock){
                  sectionData.blocks.map(block => {
                    if(block.id === sectionBlock.id){
                        block.fields.push(field)
                    }
                  })
                }else{
                  let newBlock = {
                    id: field.block_id,
                    fields: []
                  }
                  newBlock.fields.push(field)
                  sectionData.blocks.push(newBlock)
                }
            }

              section.fields.map(field => {
                if(field.block_id){
                    addToBlock(field)
                }else{
                  sectionData.fields.push(field)
                }
              })
          
          sectionsList.push(sectionData)
        })
        return sectionsList
    },

    // End Format Sections


    // section Modal control
    closeSectionModal(){
      this.$refs["section-modal"].hideModal();
    },

    showSectionModal() {
      this.sectionName = ""
      this.$refs["section-modal"].showModal();
    },

    // End Section Modal control

    // section add field Modal control
    closefieldAddModal(){
      this.$refs["section-addfield-modal"].hideModal();
    },

    async showfieldAddModal(section, block) {
        let permisions = this.allRoles && this.allRoles?.map(permision => ({...permision, role_read: false, role_write: false, role_visible: false, role_mandatory: false}))
        this.fieldToAdd = {
            section: section,
            block: block ? block : null,
            field: null,
            permisions: permisions
            
        }
        this.$refs["section-addfield-modal"].showModal();
    },

    // End Section add field Modal control
     
    // Remove section

    async handleRemoveSection(section) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This section  will be deleted permanently. Please confirm to continue deleting this section.",
      });
      if (promise) {
        let url = `/tenant-check/section`;
        let payload = {
          check_id: this.checkData.id,
          section_id: section.id
        }
        let {data} = await axios.delete(url, { data: payload });
        
        try {
            this.$toast.success(data.message);
            let newList = this.sectionList.filter((ele) => ele.id !== section.id);
            this.sectionList = newList;
        } catch(error){
            this.$toast.error(error.response.data.detail || 'Failed to remove section');
        }

      }
      this.$refs["confirm-popup"].close();
      
    },

    // End Remove section

    // handle Add Field to Sections 
    async addFieldToSection(){
            let {block, section, field, permisions} = this.fieldToAdd
            let new_permisions = []
            permisions?.map(permision => {
                let payload_permision= {
                "role_id":permision.id,
                "role_read": permision.role_read,
                "role_write": permision.role_write,
                "role_visible": permision.role_visible,
                "role_mandatory": permision.role_mandatory
            }
            new_permisions.push(payload_permision)
        })
      
      let payload = {
              "check_name": field.label,
              "field_id": field.id,
              "tenant_check_id": this.checkData.id,
              "field_details": {
                "field_global_id": field.id,
                "section_id": section.id,
                "internal_use_only": false,
                "block_id": null,
                "multi": false,
                "max_field_or_block_count": 1,
                "min_field_or_block_count": 1,
                "is_top_level": false
              },
              "permissions":  new_permisions
            }
            
      if(block){
          payload.field_details.block_id = block.id
      }
      let url = `/tenant-check/section/${section.id}/field/add`;
      try{
        let {data} = await axios.post(url, payload);
        if(data.section_field_id){
            this.sectionList.filter(section => {
                if(section.id === section.id){
                    if(block){
                    section.blocks?.filter(blockData => {
                        if(blockData.id === block.id){
                            block.fields.push({...payload, id:data.section_field_id, label:field.label, name:field.name, placeholder:field.placeholder, roles:permisions.map(per => ({...per, role_id:per.id}))})
                        }
                    })
                }else{
                    section.fields.push({...payload, id:data.section_field_id, label:field.label, name:field.name, placeholder:field.placeholder, roles:permisions.map(per => ({...per, role_id:per.id}))})
                }
                }
            })
            // await this.getCheckDetails()
            this.$toast.success(data.message);
        }
        
      }
      catch(error){
          this.$toast.error("error while adding field to sections");
      }
      this.closefieldAddModal()
    }, 


    handleCollapseSecrion(section) {
      this.sectionList.filter((ele) => {
        if (ele.id === section.id) {
          ele.expanded = !ele.expanded;
        }
      });
    },
    async addNewSection() {
      if(!this.sectionName) return this.$toast.error("Section Name required");
      let url = `/tenant-check/${this.checkData.id}/section`;
      let payload = {
          name: this.sectionName,
          section_fields:[]
      }
      try{
        let {data} = await axios.post(url, payload);
        let newSection = {
          id: data.section_id,
          name: this.sectionName,
          expanded: true,
          blocks: [],
          fields: []

        }
        this.sectionList.push(newSection);
        this.$toast.success(data.message); 
      
      }catch(error){
        this.$toast.error(error.response.data.detail || 'Failed to add new section');
      }
      this.closeSectionModal()
      
    },

  // handle Delete Field form section and Block

    async handleDeleteField(field, section, sectionBlock) {

      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This field  will be deleted permanently. Please confirm to continue deleting this field.",
      });

      if (promise) {
        let url = `/tenant-check/field`;
        let payload = {
          check_id: this.checkData.id,
          section_field_id: field.id,
          tenant_id: this.tenantId
        }
        let {data} = await axios.delete(url, { data: payload });
        try{
          this.$toast.success(data.message); 
          if(sectionBlock){
              this
              this.sectionList.map(sec => {
                if(sec.id === section.id){
                  sec.blocks.map(block => {
                    if(block.id === sectionBlock.id){
                      return block.fields = block.fields.filter(el => el.id !== field.id)
                    }
                  })
                }
              })
          }else{
            this.sectionList.map(sec => {
                if(sec.id === section.id){
                  return sec.fields = sec.fields.filter(el => el.id !== field.id)
                }
              })
          }
        }
        catch(error){
            console.log(error, "Error")
        }
      }
      this.$refs["confirm-popup"].close();
      this.handleClosePanel()
    },

    // End Delete Field from section and block


    // update Section Label
    async updateSectionLabel(sectionData){
        if(this.oldSectionName !== sectionData.name){
            const url = `/tenant-check/${this.checkData.id}/section/rename`;
            let payload ={
              section_id: sectionData.id,
              name: sectionData.name
            }
            try{
                const {data} = await axios.post(url, payload);
                this.$toast.success(data.message);
            }
            catch(error){  
                this.$toast.warning(error.response.data.detail || 'Failed to rename section name!');
            }
        }
    },

    sectionNameClick(sectionData){
      this.oldSectionName = sectionData.name
    },

    // Update field Name

    async updateFieldLabel(sectionData, checkData){
        if(this.oldFieldName !== checkData.label){
            const url = `/tenant-check/${this.checkData.id}/section/${sectionData.id}/field`;
            let payload ={
              section_field_id: checkData.id,
              label: checkData.label,
              permissions: checkData.roles
            }
            try{
                const {data} = await axios.put(url, payload);
                this.$toast.success(data.message);
            }
            catch(error){  
                this.$toast.warning(error.response.data.detail || "Failed to rename Filed name!");
            }
        }
    },

    fieldNameClick(checkData){
      this.oldFieldName = checkData.label
    },

    handleFieldChange(e){
      if(e.moved){
        this.$toast.success("Field Reordered");
        
      }
    },
    hanldeSectionReorder(e){
      if(e.moved){
        this.$toast.success("Section Reordered");
        
      }
    },
    handleAddFieldChange(e){
      console.log(e, "add field Event")
    },

    handleShowPanel(element, section) {
      this.showSidePanel = true,
      this.activeFieldPanel =  {
        field: element,
        section: section
      }
    },
    handleClosePanel(){
      this.showSidePanel = false,
      this.activeFieldPanel = {
        field: null,
        section: null
      }
    },
    fetchChecks(){
        let query = this.$route
        let checkId = query.params.id
        let checkData = null
        if(checkId){
            checkData = rows.find(ele => parseInt(ele.id) === parseInt(checkId))
        }
        this.checkData = checkData
    },
    
    // Handle Save Check ----------------- 

    async handleSaveCheck(){
      let checkInfo = this.checkData
      let url = `/tenant-check/${this.tenantId}/${checkInfo.id}`;
      let playload = {
        name: checkInfo.name,
        description: checkInfo.description
      }
      let {data} = await axios.put(url, playload);
      try{
        this.$toast.success(data.message);
      }catch(error){
        console.log(error, "Error")
      }
    },

    //  Save Check End ----------------- 

  },
};
</script>
<style scoped lang="scss">
.list-group-item.child:hover {
  border-right: 6px solid var(--dv-brand-color);
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.4;
  background: rgba(93, 121, 199, 0.157);
  border: 2px dotted var(--dv-brand-color);
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.section_edit_icon{
  display: block;
}
.section_name {
    &:active, &:focus {
     border-bottom: 1px dashed rgb(207, 207, 207);
    }
}

.section_name:focus + .section_edit_icon{
  display: none;
}
.visibility_row input[type=checkbox]{
    width: 1.3rem;
    height: 1.3rem;;
}



::v-deep{
    .multiselect__tags{
        min-height: 0px !important;
    }
    .multiselect__content-wrapper{
        position: relative !important;
    }
    .multiselect__option{
        min-height: 0 !important;
    }

}

</style>